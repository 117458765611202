window.scrollToTop = function(elementName) {
    var elmnt = document.getElementById(elementName);
    elmnt.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start',
        inline: 'center' 
      },); // Top     
}

window.saveUserAgreement = function() {
    if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("zgoda", "yes");
    } else {
        // Sorry! No Web Storage support..
        window.setCookie("zgoda", "yes", 3600);
    }
    window.hideCookiesPanel();
}

window.checkUserAgreement = function() {
    var val = 'no';
    if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        val = localStorage.getItem("zgoda", "yes");

    } else {
        // Sorry! No Web Storage support..
        val = window.getCookie("zgoda");
    }
    if (val == "yes"){
        window.hideCookiesPanel();       
    } else {
        window.showCookiesPanel();
    }


}

window.showCookiesPanel = function () {
    var x = document.getElementById("cookiePanel");
    if (x.style.display === "none") {
        x.style.display = "block";
    } 
}

window.hideCookiesPanel = function () {
    var x = document.getElementById("cookiePanel");
    if (x.style.display === "none") {
      //  x.style.display = "block";
    } else {
        x.style.display = "none";
    }
}

window.test = function(){
    window.setCookie("zgoda", "yes", 3600);
    var value = window.getCookie("zgoda");
    window.alert(value);
    if (value == "yes"){
        window.alert("Wartość równa yes");
    }
}


window.setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

window.getCookie = function(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


window.onresize = function(event) {
    var width = document.getElementById('appheader').offsetWidth;
    document.getElementById('myMask').style.width=width + 150;
    var widthMask = document.getElementById('myMask').offsetWidth;
    //console.log("zmiana rozmiaru myMask:" + widthMask );
};