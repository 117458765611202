//console.log(`I'm a silly entry point`);

//const arr = [1, 2, 3];
//const iAmJavascriptES6 = () => console.log(...arr);
//window.iAmJavascriptES6 = iAmJavascriptES6;

import App from "./App";
//import Warunki from "./Warunki";
import "./css/index.css";
import "./main.css";
import "./css/App.css";
import "./css/Body.css";
import "./css/Footer.css";
import "./css/General_content.css";
import "./css/Header.css";
import "./css/uslugi.css";




import './style.scss';
//import image from './images/test.jpg';

//import 'bootstrap';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/util';

import 'bootstrap/dist/css/bootstrap.min.css';

import './func';


if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('SW registered: ', registration);
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }