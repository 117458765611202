import React from "react";
import ReactDOM from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 49.830824, lng: 19.933929 }}
  >
    <Marker
      position={{ lat: 49.830824, lng: 19.933929 }}
    />
  </GoogleMap>
));

const App = () => {
  return (
    <div>
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCvp448WuFMlkJzTkv7V6M64cVSadyC_mI&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%`, width: `50%` }} />}
        containerElement={<div style={{ height: `400px`, width: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      /> 
    </div>
  );
};
export default App;
ReactDOM.render(<App />, document.getElementById("app"));